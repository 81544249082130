'use client';

import { ErrorLogger } from '@actions/logger/logger';
import NextError from 'next/error';
import { useEffect } from 'react';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
    
  useEffect(() => {
    ErrorLogger(error.message);    
  }, [error]);

  return (
    <html>
      <body>        
        <NextError statusCode={0} />
      </body>
    </html>
  );
}
